import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { useAuth } from '../services/use-auth';


const AuthRoute = ({ children, ...rest }) => {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (auth && auth.user) ? (
                    <Redirect
                        to={{
                            pathname: "/register",
                            state: { from: location }
                        }}
                    />
                ) : (
                        children
                    )
            }
        />
    );
}

export default withRouter(AuthRoute);
