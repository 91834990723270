
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Switch, Link, withRouter, useHistory } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import CustomizeProgram from './Customizeprogram';
import './Terms.css';
import editIcon from '../images/edit-icon.svg';
import { ProvideAuth, useAuth } from '../services/use-auth';

const ProgramCard = (props) => {
    const auth = useAuth();
    const history = useHistory();
    const [program, updateProgram] = useState(props.program);
    var removeProgramHandler = props.removeProgramHandler;
    var customizeProgramHandler = props.customizeProgramHandler;

    useEffect(() => {

    }, [program]);

    return (
        <div className="program-card-wrap">
        {program.selected ?
            <div className="program-card selected">
                <h2 className="title">{program.name}</h2>
                <div className="quote">
                    <table>
                        <tbody>
                            <tr><td><strong>3-Month Quote:</strong></td><td><strong>{'INR ' + program.quote.price_3m}</strong></td></tr>
                            {program.quote.discount_3m && <tr><td>Max discount</td><td>INR {program.quote.discount_3m}</td></tr>}
                            <tr className="checked-note"><td>{program.quote.subscription_3m && '✓ Monthly payment'}</td></tr>
                            <tr><td className="vertical-spacing"></td></tr>
                            <tr><td><strong>6-Month Quote:</strong></td><td><strong>{'INR ' + program.quote.price_6m}</strong></td></tr>
                            {program.quote.discount_6m && <tr><td>Max discount</td><td> INR {program.quote.discount_6m}</td></tr>}
                            <tr className="checked-note"><td>{program.quote.subscription_6m && '✓ Payment in three parts'}</td></tr>
                        </tbody>
                    </table>
                </div>
                <div className="button-wrap">
                    <button className="cbtn cbtn-edit cbtn-medium" onClick={customizeProgram}>Customize</button>
                    <button id={program.id} className="cbtn cbtn-danger cbtn-medium" onClick={removeProgram} >Remove</button>
                </div>
            </div>
            :
            <div className="program-card">
                <h2 className="title">{program.name}</h2>
                
                <button className="cbtn cbtn-success cbtn-center cbtn-medium" onClick={customizeProgram}>Select </button>
            </div>
        }
        </div>
    )

    function removeProgram() {
        program.selected = false;
        removeProgramHandler(program, function(){
            auth.invalidate();
        });
    }

    function customizeProgram() {
        customizeProgramHandler(program);
    }
}

export default ProgramCard;