function toaster() {
    function successElement(msg) {
        if(!msg)
            msg = "Request submitted successfully!!";
    
        return '<div class="success-msg">\
                    <div class="msg">' + msg + '</div>\
                </div>';
    };
    
    function errorElement(msg) {
        if(!msg)
            msg = "Oops! Error. Please try again.";
    
        return '<div class="error-msg" >\
                    <div class="msg">' + msg + '</div>\
                </div>';
    };
    
    function warningElement(msg) {
        if(!msg)
            msg = "Some error occurred but no need to worry.";
    
        return '<div class="warning-msg" >\
                    <div class="msg">' + msg + '</div>\
                </div>';
    };
    
    function toastTimer(delay) {
        if(!delay)
            delay = 3000;
    
        setTimeout(function(){
            document.getElementById("response").innerHTML = '';
        }, delay);
    };
    
    const success = (message) => {
        document.getElementById("response").innerHTML = successElement(message);
        toastTimer();
    }
    
    const error = (message) => {
        document.getElementById("response").innerHTML = errorElement(message);
        toastTimer();
    }
    
    const warning = (message) => {
        document.getElementById("response").innerHTML = warningElement(message);
        toastTimer();
    }

    return {
        success,
        error,
        warning
    }
}

const Toast = toaster()
export default Toast;

