import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Login from './Login';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import './../App.css';
import './Login.css';
import { useAuth } from '../services/use-auth';
import AuthRoute from './AuthRoute';
import Popup from './Homescreenpopup';

import Logo from './Bluebrand';

const Intropage = () => {
    
    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }
    
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
        return (
            <div className="introduction">
                <Popup/>
                <div className="logo">
                    <Logo />
                </div>
               <h5 className="description">Integrative Care and Participatory Health Platform</h5>
               <Link to='/login' className="login login-btn">LOG IN</Link>
            </div>
            
        );
    }

    return (<AuthRoute>
        <div className="introduction">
            <div className="logo">
                <Logo />
            </div>
            <h5 className="description">Integrative Care and Participatory Health Platform</h5>
            <Link to='/login' className="login login-btn">LOG IN</Link>
        </div>
        </AuthRoute>    
    );
}   
export default withRouter(Intropage);