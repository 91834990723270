import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import menu from './../images/menu-icon.svg';
// import message from './../images/message-notify.svg';
import Menubar from './Menubar';
import './Terms.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory, withRouter } from 'react-router-dom';
import close from './../images/close.svg';
import homeIcon from './../images/home.svg';
import mywallet from './../images/wallet.svg';
import myaccount from './../images/account.svg';
import customizeIcon from './../images/checklist.svg';
import signout from './../images/logout.svg';
import supportIcon from './../images/support.svg';
import logo from './../images/logosmall.png';
import settings from './../images/settings.svg';
import App from './../App';
import { ProvideAuth, useAuth } from '../services/use-auth';

const Navigation = (props) => {
    const history = useHistory();
    const auth = useAuth();
    const [displayTitle, setDisplayTitle] = useState(false);
    var title = props.pageTitle;

    const navLink = {
        'home': history.location.pathname.includes("/home") ?
            <a href="/home"><img src={homeIcon} alt="img" onClick={renderNavigation} />Home</a> :
            <Link to="/home"><img src={homeIcon} alt="img" onClick={renderNavigation} />Home</Link>,

        'account': history.location.pathname.includes("/account") ?
            <a href="/account"><img src={myaccount} alt="img" onClick={renderNavigation} />My Account</a> :
            <Link to="/account"><img src={myaccount} alt="img" onClick={renderNavigation} />My Account</Link>,

        'wallet': history.location.pathname.includes("/wallet") ?
            <a href="/wallet"><img src={mywallet} alt="img" onClick={renderNavigation} />My Wallet</a> :
            <Link to="/wallet"><img src={mywallet} alt="img" onClick={renderNavigation} />My Wallet</Link>,

        'customize': history.location.pathname.includes("/customize") ?
        <a href="/customize"><img src={customizeIcon} alt="img" onClick={renderNavigation} />Select Programs</a> :
        <Link to="/customize"><img src={customizeIcon} alt="img" onClick={renderNavigation} />Select Programs</Link>
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <div className="navigation">
            <div className="navigation-bar">
                <div className="hamburger-menu-wrap">
                    <img className="hamburger-menu" src={menu} alt="menu" onClick={openMenu} id="hamburger"/>
                </div>
                <div className="nav-title">
                { displayTitle ? <span>{title}</span>: <img className="logo" src={logo} alt="image" style={{width: '50px' }}/>  }
                </div>
                <div style={{visibility: "hidden"}} className="notification-wrap">
                    <img className="hamburger-menu" src={menu} alt="image" id="notification"/>
                </div>
            </div>
            <div className="menubar" style={{display: 'none' }} id="menubar">
                <nav>
                    <div className='closeBtnContainer'>
                        <img src={close} alt="img" className='close-btn' onClick={closeMenu} />
                    </div>
                    <ul className="nav-links">
                        <li className="nav-items">
                            {navLink.home}
                        </li>
                        <li className="nav-items">
                            {navLink.account}
                        </li>
                        <li className="nav-items">
                            {navLink.wallet}
                        </li>
                        <li className="nav-items">
                            <Link to="/support" ><img src={supportIcon} alt="img" onClick={renderNavigation} />Support</Link>
                        </li>
                        <li className="nav-items">
                            {navLink.customize}
                        </li>
                        <li className="nav-items">
                            <Link to="/logout" ><img src={signout} alt="img" onClick={renderNavigation} />Logout</Link>
                        </li>
                    </ul>
                    <p className="app-version">App version: {auth.getAppVersion()}</p>
                </nav>
            </div>
        </div>
    )

    function renderNavigation() {

        ReactDOM.render(
            <ProvideAuth>
                <Router>
                    <App />
                </Router>
            </ProvideAuth>,
            document.getElementById('root')

        );
    }

    function openMenu() {
        //setDisplayMenu({});
        var hamburger = document.getElementById("hamburger");
        hamburger.style.visibility = "hidden";

        var menubar = document.getElementById("menubar");
        menubar.style.display = "flex";

    }

    function closeMenu() {
        //setDisplayMenu({ display: 'none' });
        var hamburger = document.getElementById("hamburger");
        hamburger.style.visibility = "unset";

        var menubar = document.getElementById("menubar");
        menubar.style.display = "none";
    }

    function handleScroll()
    {
        if(window.pageYOffset >= 53)
        {
            setDisplayTitle(true);
        }
        else{
            setDisplayTitle(false);
        }            
    }
}

export default Navigation;