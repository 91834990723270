import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useForm } from "react-hook-form";
import { BrowserRouter as Router, Route, Link, Redirect, withRouter, useHistory } from 'react-router-dom';
import './Login.css';
import './Register.css';
import TermsandConditions from './Termsandconditions';
import { useAuth } from '../services/use-auth';
import PrivateRoute from './PrivateRoute';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';

let values = {};
const Register = (props) => {
    const auth = useAuth();
    const history = useHistory();
    const [staticData, setStaticData] = useState(auth.user.static_data || []);
    useEffect(() => {

    }, []);

    return (auth && auth.user && auth.user.metainfo && auth.user.metainfo.medical_registration_number != '') ? (<Redirect to="/home" />)
     : (
        <PrivateRoute>
            <div className="register">
            <h1 className="title">Create Your Profile</h1>
            <form  className="register-form">
                <label>First name</label>
                <input type="text" id="fname" defaultValue={auth.user.first_name ? auth.user.first_name : null} className="inputField" placeholder="Enter your first name" required />
                <span className="field-error" id="fnameerror"></span>

                <label>Last name</label>
                <input type="text" id="lname" defaultValue={auth.user.last_name ? auth.user.last_name : null} className="inputField" placeholder="Enter your last name" required />
                <span className="field-error" id="lnameerror"></span>

                <label>Email</label>
                <input type="email" id="e-mail" defaultValue={auth.user.email ? auth.user.email : null} className="inputField" placeholder="Enter your email" required />
                <span className="field-error" id="emailerror"></span>
                
                <label>Medical council</label>
                <select id="council-dropdown" defaultValue={auth.user.metainfo && auth.user.metainfo.medical_council ? auth.user.metainfo.medical_council : null} className="inputField">
                    <option value="">--- Please select ---</option>
                    {staticData && staticData.medical_councils && staticData.medical_councils.map( (council) => 
                        <option key={council} value={council}>{council}</option>
                    )}
                </select>
                <span className="field-error" id="councilerror"></span>
                
                <label>Medical registration number</label>
                <input type="text" defaultValue={auth.user.metainfo && auth.user.metainfo.medical_registration_number ? auth.user.metainfo.medical_registration_number : null} id="reg-num" className="inputField" placeholder="Enter registration number"/>
                <span className="field-error" id="regNumerror"></span>

                <label>Specialization</label>
                <select  id="specialization-dropdown" defaultValue={auth.user.metainfo && auth.user.metainfo.specialization ? auth.user.metainfo.specialization : null} className="inputField">
                    <option value="">--- Please select ---</option>
                    {staticData && staticData.specializations && staticData.specializations.map( (specialization) => 
                        <option key={specialization} value={specialization}>{specialization}</option>
                    )}
                </select>
                <span className="field-error" id="specializationerror"></span>
               <button type="button" value="submit" className="register-btn" onClick={createProfile}>REGISTER</button>
            </form>
        </div>
        </PrivateRoute>
    )

    function createProfile(e) {
        e.preventDefault();

        var globalError = false;
        var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var Error = "This field cannot be empty";
        var firstName = document.getElementById('fname');
        var lastName = document.getElementById('lname');
        var email = document.getElementById('e-mail');
        var medicalCouncil = document.getElementById('council-dropdown');
        var regNumber = document.getElementById('reg-num');
        var specialization = document.getElementById('specialization-dropdown');
        var fname_error = document.getElementById('fnameerror');
        var lname_error = document.getElementById('lnameerror');
        var email_error = document.getElementById('emailerror');
        var council_error = document.getElementById('councilerror');
        var reg_error = document.getElementById('regNumerror');
        var specialization_error = document.getElementById('specializationerror');

        if(firstName.value || lastName.value || email.value || medicalCouncil.value || regNumber.value || specialization.value )
        {
            firstName.classList.remove('invalid');
            fname_error.style.display = "none"; 

            if(lastName.value) 
            {
                lastName.classList.remove('invalid');
                lname_error.style.display = "none"; 
                

                if(email.value.match(emailformat) )
                {
                    email_error.style.display = "none";
                    email.classList.remove("invalid");

                    if(medicalCouncil.value)
                    {
                        council_error.style.display = "none";
                        medicalCouncil.classList.remove("invalid");

                        if(regNumber.value.length <= 10) 
                        {
                            reg_error.style.display = "none";
                            regNumber.classList.remove("invalid"); 
                            
                            if(specialization.value)
                            {
                                specialization_error.style.display = "none";
                                specialization.classList.remove("invalid");
                                
                            }
                            else 
                            {
                                globalError = true;
                                specialization.classList.add('invalid');
                                specialization_error.style.display = "inline";
                                specialization_error.innerHTML = Error;
                            }   
                        }
                        else 
                        {
                            globalError = true;
                            regNumber.classList.add('invalid');
                            reg_error.style.display = "inline";
                            reg_error.innerHTML = Error; 
                        }   
                    }    
                    else 
                    {
                        globalError = true;
                        medicalCouncil.classList.add('invalid');
                        council_error.style.display = "inline";
                        council_error.innerHTML = Error;
                    }  
                }
                else if(email.value.length == 0) {
                    globalError = true;
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = Error;
                }
                else 
                {
                    globalError = true;
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = emailvalueError;
                }
            }     
            else 
            {
                globalError = true;
                lname_error.style.display = "inline";
                lastName.classList.add("invalid");
                lname_error.innerHTML = namevalueError;
            }    
        }
        else 
        {
            globalError = true;
            fname_error.style.display = "inline";
            firstName.classList.add("invalid");
            fname_error.innerHTML = namevalueError;

            lname_error.style.display = "inline";
            lastName.classList.add("invalid");
            lname_error.innerHTML = namevalueError;

            email_error.style.display = "inline";
            email.classList.add("invalid");
            email_error.innerHTML = Error;

            medicalCouncil.classList.add('invalid');
            council_error.style.display = "inline";
            council_error.innerHTML = Error;

            regNumber.classList.add('invalid');
            reg_error.style.display = "inline";
            reg_error.innerHTML = Error; 

            specialization.classList.add('invalid');
            specialization_error.style.display = "inline";
            specialization_error.innerHTML = Error;
        }

        if(!globalError) {
            // props.history.push('/termsandcondition');

            Resource.createProfile({
                'first_name': firstName.value,
                'last_name': lastName.value,
                'email': email.value,
                'contact_number': auth.user.username,
                'metainfo': {
                    'medical_council': medicalCouncil.value,
                    'medical_registration_number': regNumber.value,
                    'specialization': specialization.value
                }   
            }).then( (response)=> {
                if(response.success) {
                    auth.getConfig({role: "doctor"}).then( (resp) => {
                        if(resp) {
                            Toast.success("Great. You will be notified as soon as your account is activated. ");
                            props.history.push('/home')
                        }
                        else {
                            Toast.success("Please login again.");
                            auth.signout();
                        }
                        /*ReactDOM.render(
                            <Router history={history}>
                                <PrivateRoute path='/terms'/>
                            </Router>,
                            document.getElementById("root")
                        );*/
                    });
                    
                }
                else if(response.error) {
                    Toast.error("Oops. There's some error. Please try again.");
                }
            });
        }
    }
} 
export default withRouter(Register);