import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Navigation from './Navigation';
import PatientdetailsCard from './PatientdetailsCard';
import Addpatientform from './Addpatientform';
import './Menu.css';
import { ProvideAuth, useAuth } from '../services/use-auth';
import PrivateRoute from './PrivateRoute';
import Resource from './../services/resource.service';
import { BrowserRouter as Router, Route, Link, Switch, withRouter, useHistory } from 'react-router-dom';
import Toast from './../helpers/toaster'

const Home = (props) => {
    const history = useHistory();
    console.log('history', history);
    const auth = useAuth();
    const [patients, setPatients] = useState([]);
    
    useEffect(() => {

        if (auth && auth.user) {
            Resource.getPatients().then((p) => {
                console.log(p);
                if (p.success) {
                    setPatients(p.data);
                }
            });
        }
    }, [auth.user]);

    return (
        <div >
            <Navigation pageTitle={patients.length == 0 ? 'No Patients' : (patients.length == 1) ? '1 Patient' : (patients.length + ' Patients') + ' Referred'} />
            <div className="topic">
                <h1 className="title" >{patients.length == 0 ? 'No Patients' : (patients.length == 1) ? '1 Patient' : (patients.length + ' Patients') + ' Referred'}</h1>
            </div>
            <div className='home'>
                <PatientdetailsCard userdetails={patients} />
                {
                    auth.user ?
                    <div className='btn-holder'>
                        <Link to="/add-patient" className="addpatient"><button type="button" value="submit" className="add-btn" onClick={addPatient}>ADD NEW PATIENT</button></Link>
                    </div>
                    :
                    ''
                }
            </div>
        </div>
    )


    function addPatient() {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <PrivateRoute>
                        <Addpatientform path="/add-patient" />
                    </PrivateRoute>
                </Router>
            </ProvideAuth>,
            document.getElementById("root")
        )
    }

}

export default withRouter(Home);