import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from "react-hook-form";
import close from './../images/close.svg';
import Account from './Account';
import Navigation from './Navigation';
import './Register.css';
import './Login.css';
import PrivateRoute from './PrivateRoute';
import { ProvideAuth, useAuth } from '../services/use-auth';
import { BrowserRouter as Router, Redirect, Switch, Link, withRouter, useHistory } from 'react-router-dom';
import App from '../App';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';

const CustomizeProgram = (props) => {
    const removeProgramHandler = props.removeProgramHandler;
    const auth = useAuth();
    const history = useHistory();
    const [program, updateProgram] = useState(props.program);
    const [calc, updateCalc] = useState({
        profit_3m: program.quote.price_3m - program.quote.cost_3m,
        profit_6m: program.quote.price_6m - program.quote.cost_6m,
        discounted_profit_3m: program.quote.price_3m - program.quote.cost_3m - program.quote.discount_3m,
        discounted_profit_6m: program.quote.price_6m - program.quote.cost_6m - program.quote.discount_6m
    });
    useEffect(() => {
        if(program.quote.price_3m)
            updateCalc({
                profit_3m: program.quote.price_3m - program.quote.cost_3m,
                profit_6m: program.quote.price_6m - program.quote.cost_6m,
                discounted_profit_3m: program.quote.price_3m - program.quote.cost_3m - program.quote.discount_3m,
                discounted_profit_6m: program.quote.price_6m - program.quote.cost_6m - program.quote.discount_6m
            });
    }, [program]);

    return (
        <div className="edit-page">
            <div className='closeBtnContainer'>
                <Link to="/customize" ><img src={close} alt="img" className='close-btn' onClick={backToCustomize} /></Link>
            </div>
            <h1 className="title center">Customize <br/><span>{program.name}</span><br/>Program</h1>
            <form id="edit-form">
                {program.selected ? <button type="button" className="cbtn cbtn-danger" onClick={removeProgram}>Remove Program</button> : ''}
                <div className="input-group">
                    <h2>3-Month Program</h2>
                    <div className="service-charge">MD+ service charge: <strong>INR {program.quote.cost_3m}</strong></div>
                    <label>Enter quote for patients</label>
                    <input onChange={handleChange} min='6000' type="number" id="price_3m" defaultValue={program.quote.price_3m ? program.quote.price_3m : null} className="inputField" placeholder="Enter your quote" required />
                    <span className="field-error" id="quote_price_3m_error">Minimum allowed quote {program.currency} {program.quote.cost_3m}</span>
                    {/*}
                    <label>Enter max discount to offer</label>
                    <input onChange={handleChange} type="number" id="discount_3m" defaultValue={program.quote.discount_3m ? program.quote.discount_3m : null} className="inputField" placeholder="Enter discount (Optional)" />
                    <span className="field-error" id="quote_discount_3m_error"></span>
                    */}

                    <div className="input-field-wrap">
                        <input onChange={handleChange} className="input-checkbox inputField" type="checkbox" id="subscription_3m" defaultChecked={program.quote.subscription_3m ? program.quote.subscription_3m : false} />
                        <div className="checkbox-text">Allow monthly payment?</div>
                    </div>

                    <div className="info-text">
                        <ul>
                            {
                                program.quote.discount_3m && program.quote.discount_3m > 0 ? (
                                    program.quote.subscription_3m ? (
                                        calc.profit_3m >= 0 ? <li>You will make total of <strong>INR {calc.discounted_profit_3m} - INR {calc.profit_3m}</strong> (in three parts of INR {parseInt(calc.discounted_profit_3m/3, 10)} - INR {parseInt(calc.profit_3m/3, 10)} each)</li> : ''
                                    ) : 
                                    (calc.profit_3m >= 0 ? <li>You will make <strong>INR {calc.discounted_profit_3m} - INR {calc.profit_3m}</strong></li> : '')
                                ) : (
                                    program.quote.subscription_3m ? (
                                        calc.profit_3m >= 0 ? <li>You will make total of <strong>INR {calc.profit_3m} </strong> (in three parts of INR {parseInt(calc.profit_3m/3, 10)} each)</li> : ''
                                    ) : 
                                    (calc.profit_3m >= 0 ? <li>You will make <strong>INR {calc.profit_3m}</strong></li> : '')
                                )
                        }
                        </ul>
                    </div>
                    <hr/>
                </div>
                <div className="input-group">
                    <h2>6-Month Program</h2>
                    <div className="service-charge">MD+ service charge: <strong>INR {program.quote.cost_6m}</strong></div>
                    <label>Enter quote for patients</label>
                    <input onChange={handleChange} min='10000' type="number" id="price_6m" defaultValue={program.quote.price_6m ? program.quote.price_6m : null} className="inputField" placeholder="Enter your quote" required />
                    <span className="field-error" id="quote_price_6m_error">Minimum allowed quote {program.currency} {program.quote.cost_6m}</span>
                    {/*
                    <label>Enter max discount to offer</label>
                    <input onChange={handleChange} type="number" id="discount_6m" defaultValue={program.quote.discount_6m ? program.quote.discount_6m : null} className="inputField" placeholder="Enter discount (Optional)" />
                    <span className="field-error" id="quote_discount_6m_error"></span>
                    */}

                    <div className="input-field-wrap">
                        <input onChange={handleChange} className="input-checkbox inputField" type="checkbox" id="subscription_6m" defaultChecked={program.quote.subscription_6m ? program.quote.subscription_6m : false} />
                        <div className="checkbox-text">Allow payment in 3 parts?</div>
                    </div>

                    <div className="info-text">
                        <ul>
                            {
                                program.quote.discount_6m && program.quote.discount_6m > 0 ? (
                                    program.quote.subscription_6m ? (
                                        calc.profit_6m >= 0 ? <li>You will make total of <strong>INR {calc.discounted_profit_6m} - INR {calc.profit_6m}</strong> (in three parts of INR {parseInt(calc.discounted_profit_6m/3, 10)} - INR {parseInt(calc.profit_6m/3, 10)} each)</li> : ''
                                    ) : 
                                    (calc.profit_6m >= 0 ? <li>You will make <strong>INR {calc.discounted_profit_6m} - INR {calc.profit_6m}</strong></li> : '')
                                ) : (
                                    program.quote.subscription_6m ? (
                                        calc.profit_6m >= 0 ? <li>You will make total of <strong>INR {calc.profit_6m}</strong> (in three parts of INR {parseInt(calc.profit_6m/3, 10)} each)</li> : ''
                                    ) : 
                                    (calc.profit_6m >= 0 ? <li>You will make <strong>INR {calc.profit_6m}</strong></li> : '')
                                )
                            }
                        </ul>
                    </div>
                </div>
                <button type="button" value="submit" className="save-btn" onClick={saveDetails.bind(this)}>SAVE</button>
            </form>
        </div>
    )

    function handleChange(e) {
        let tempProgram = {}; 
        Object.assign(tempProgram, program);
        switch(e.target.type) {
            case 'checkbox':
                tempProgram.quote[e.target.id] = e.target.checked;
                break;
            default:
                tempProgram.quote[e.target.id] = parseInt(e.target.value, 10);
        }
        updateProgram(tempProgram);
    }

    function removeProgram() {
        program.selected = false;
        removeProgramHandler(program, function(){
            auth.invalidate();
            backToCustomize();
        });
    }

    function saveDetails(e) {
        e.preventDefault();
        var globalError = false;
        
        var price3m = document.getElementById('price_3m');
        var price6m = document.getElementById('price_6m');
        var errorPrice3m = document.getElementById('quote_price_3m_error');
        var errorPrice6m = document.getElementById('quote_price_6m_error');

        errorPrice3m.style.display = "none";
        price3m.classList.remove("invalid");

        errorPrice6m.style.display = "none";
        price6m.classList.remove("invalid");

        if(price3m.value || price6m.value) {
            if(!price3m.value || price3m.value < program.quote.cost_3m) {
                globalError = true;
                errorPrice3m.style.display = "block";
                price3m.classList.add("invalid");
            }
            
            if(!price6m.value || price6m.value < program.quote.cost_6m) {
                globalError = true;
                errorPrice6m.style.display = "block";
                price6m.classList.add("invalid");
            }
        }
        else {
            globalError = true;
            errorPrice3m.style.display = "block";
            price3m.classList.add("invalid");

            errorPrice6m.style.display = "block";
            price6m.classList.add("invalid");
        }
        
        if(!globalError) {
            program.selected = true;
            Resource.addUpdateSelectedProgram(program).then((response) => {
                if (response.success) {
                    let msg = "Great! " + program.name + " is added.";
                    Toast.success(msg);
                    history.push('/customize');
                    auth.invalidate();
                    backToCustomize();
                }
                else {
                    program.selected = false;
                    Toast.error("Server error. Please try again in sometime");
                }
            });
        }
        else {
            Toast.error("Missing / Invalid values");
        }

    }

    function backToCustomize() {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <App />
                </Router>
            </ProvideAuth>,
            document.getElementById('root')

        )
    }
}

export default withRouter(CustomizeProgram);