import { useAuth } from '../services/use-auth';
import React from 'react';
import { Link } from 'react-router-dom';
import './Modal.css';
import { BrowserRouter as Router, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';
import close from './../images/close.svg';

const Support = (props) => {
    const history = useHistory();

    return (
        <div className="support">
            <div className='closeBtnContainer'>
                <span onClick={cancelTicket}><img src={close} alt="img" className='close-btn' /></span>
            </div>
            <h1 className="title">How can we help you?</h1>
            <form className="support-form">
                <textarea type="text" id="query" className="inputField" placeholder="Enter your query" required />
                <span className="field-error" id="queryerror"></span>
            </form>
            <button className="logout-btn" onClick={raiseTicket} >Submit</button>
        </div>
    )

    function raiseTicket() {
        var Error = "This field cannot be empty";
        var query = document.getElementById('query');
        var query_error = document.getElementById('queryerror');

        if(!query.value) {
            query.classList.add('invalid');
            query_error.style.display = "inline";
            query_error.innerHTML = Error;
        }
        else {
            query.classList.remove('invalid');
            query_error.style.display = "none";

            Resource.raiseTicket( {
                query: query.value
            }).then( (response) => {
                if(response.success) {
                    Toast.success("Query submitted successfully. We'll get back soon");
                }
                else {
                    Toast.error("Some error occurred. Please try in sometime");
                }
            });
        }
    }

    function cancelTicket() {
        history.goBack()
    }
}

export default withRouter(Support);

