import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import editIcon from './../images/edit-icon.svg';
import Navigation from "./Navigation";
import './Menu.css';
import Editaccount from './Editaccount';
import { ProvideAuth, useAuth } from '../services/use-auth';
import PrivateRoute from './PrivateRoute';
import { BrowserRouter as Router, Link, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';

const Account = () => {
    const auth = useAuth();
    const history = useHistory();
    const [doctor, setDoctor] = useState(auth.user);
    const title = "My Account";

    useEffect(() => {
        Resource.getProfile().then((profile) => {
            if(profile.success)
                setDoctor(profile.data);
            else{
                Toast.error("Unable to fetch information. Please try in sometime.");
            }
        })
    }, []);

    return (
        <div>
            <Navigation pageTitle={title}/>
            <div className="content">
                <div className="topic">
                    <h1 className="title" id="account-title">My Account</h1>
                    <Link to="/edit-account"><img src={editIcon} alt="image" onClick={editDetails} className="edit-img" /></Link>
                </div>
                <div className="my-account">
                    <div id="doctor-details">
                        <h4 className="d-name">Name</h4>
                        <h4 id="doctor-name" >{doctor.name || doctor.first_name + " " + doctor.last_name}</h4>
                        <h4 className="d-email">Email</h4>
                        <h4 id="doctor-email" >{doctor.email}</h4>
                        <h4 className="clinic">Clinic name</h4>
                        <h4 id="clinicName" >{doctor.metainfo.clinic_name ? doctor.metainfo.clinic_name : ''}</h4>
                        <h4 className="speciality">Specialization</h4>
                        <h4 id="doctor-speciality" >{doctor.metainfo.specialization ? doctor.metainfo.specialization : ''}</h4>
                        <h4 className="mobile-number">Alternate contact number</h4>
                        <h4 id="alternate-number">{doctor.contact_number ? doctor.contact_number : '+91 -'}</h4>
                    </div>
                </div>
            </div>
        </div>
    )

    function editDetails() {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <PrivateRoute>
                        <Editaccount path="/edit-account" doctor={doctor} />
                    </PrivateRoute>
                </Router>
            </ProvideAuth>,
            document.getElementById("root")
        )
    }

}

export default withRouter(Account);