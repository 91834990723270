import React from 'react';
import ReactDOM from 'react-dom';
import close from './../images/close.svg';
import './Login.css';
import './Register.css';
import App from './../App';
import { ProvideAuth, useAuth } from '../services/use-auth';
import { BrowserRouter as Router, Redirect, Switch, Link, withRouter, useHistory } from 'react-router-dom';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';


const Addpatientform = () => {
    const auth = useAuth();
    const history = useHistory();
    const selectedPrograms = auth.user.metainfo.selected_programs || [];

    return (
        <div className="add-patient">
            <div className='closeBtnContainer'>
                <Link to="/home"><img src={close} alt="img" className='close-btn' onClick={goHome} /></Link>
            </div>
            <h1 className="title">Patient Details</h1>
            <form className="addpatient-form">
                <label>Name</label>
                <input type="text" id="p-name" className="inputField" placeholder="Enter patient's name" required />
                <span className="field-error" id="nameerror"></span>
                {/*
                <label>Email</label>
                <input type="email" id="p-email" className="inputField" placeholder="Enter patient's email" required />
                <span className="field-error" id="emailerror"></span>
                */}
                <label>Phone number</label>
                <input type="number" id="p-phone" className="inputField" placeholder="Enter patient's phone number" required />
                <span className="field-error" id="phoneerror"></span>

                <label>Sex</label>
                <div className="inputFieldWrap" onClick={selectSex}>
                    <input value="F" type="radio" name="sex" id="sex-f" className="" defaultChecked="true" /><span id="sexs-f"> F</span>&emsp;
                    <input value="M" type="radio" name="sex" id="sex-m" className="" /><span id="sexs-m"> M</span><br/>
                </div>
                <span className="field-error" id="sexerror"></span>

                <label>Age</label>
                <input type="number" id="p-age" className="inputField" placeholder="Enter patient's age" required />
                <span className="field-error" id="ageerror"></span>

                <label>Program referred</label>
                <select id="program-dropdown" className="inputField" onChange={onProgramSelect}>
                    <option value="">--- Please select ---</option>
                    {selectedPrograms && selectedPrograms.map((program) =>
                        <option key={program.id} value={program.id}>{program.name}</option>
                    )}
                </select>
                <span className="field-error" id="programerror"></span>
                <label>Program duration</label>
                <div className="inputFieldWrap" onClick={selectProgramDuration}>
                    <input value="3" defaultChecked="true" type="radio" name="program-duration" id="pd-3" className="" /><span id="pdt-3"> 3 Months</span><br/>
                    <input value="6" type="radio" name="program-duration" id="pd-6" className="" /><span id="pdt-6"> 6 Months</span><br/>
                </div>
                <span className="field-error" id="programdurationerror"></span>

                <label>Quote (₹)</label>
                <input type="number" id="p-quote" className="inputField" placeholder="Enter program price" required />
                <span className="field-error" id="quoteerror"></span>

                <div className="inputFieldWrap" onClick={toggleSubscriptionCheck}>
                    <input name="subscription" id="subCheck" type="checkbox" onClick={toggleSubscriptionCheck} />&nbsp;
                    <span id="subCheckText">Allow partial payment?</span><br/>
                    <span className="note" id="note"></span>
                </div>
            </form>
            <button className="addpatient-btn" onClick={addPatient} >ADD PATIENT</button>
        </div>
    );

    function selectProgramDuration(e) {
        let pd;
        if(e.target.id == "pd-3" || e.target.id == "pdt-3")
        {
            pd = document.getElementById("pd-3");
            pd.checked = true;
        }
        else {
            pd = document.getElementById("pd-6");
            pd.checked = true;
        }
        onProgramSelect();
    }

    function onProgramSelect() {
        let programrefered = document.getElementById('program-dropdown');
        let programDurationEls = document.getElementsByName('program-duration');
        let programDurationValue = '3m';
        programDurationEls.forEach(e => {
            if (e.checked) {
                //if radio button is checked, set sort style
                programDurationValue = e.value + "m";
            }
        });

        console.log(programrefered.value);
        let referredProgram;
        selectedPrograms.forEach(p => {
            if(p.id == programrefered.value) {
                referredProgram = p;
            }
        });

        updateProgramQuote(referredProgram, programDurationValue);
        updateProgramSubscription(referredProgram, programDurationValue);
    }

    function updateProgramQuote(program, duration) {

        if(program && duration) {
            let priceToShow = program.quote['price_' + duration];
            document.getElementById('p-quote').value = priceToShow;
        }
        
    }

    function updateProgramSubscription(program, duration) {
        if(program && duration) {
            let st = document.getElementById('subCheckText');
            if(duration == '3m')
                st.innerText = 'Allow monthly payment?';
            else
                st.innerText = 'Allow payment in 3 parts?';

            let check = program.quote['subscription_' + duration];
            let s = document.getElementById('subCheck');
            s.checked = check;

            let priceToShow = document.getElementById('p-quote').value;

            let note = document.getElementById('note');
            if(duration == '3m' && s.checked && priceToShow) {
                let p = parseInt(priceToShow/3, 10);
                note.innerHTML = '<i>Patient will pay INR ' + p + ' every month.</i>'
            }
            else if(duration == '6m' && s.checked && priceToShow) {
                let p = parseInt(priceToShow/3, 10);
                note.innerHTML = '<i>Patient will pay INR ' + p + ' every two months.</i>';
            }
            else {
                note.innerHTML = '';
            }
        }
    }

    function toggleSubscriptionCheck() {
        let s = document.getElementById('subCheck');
        s.checked = !s.checked;

        let priceToShow = document.getElementById('p-quote').value;
        let programDurationEls = document.getElementsByName('program-duration');
        let duration = '3m';
        programDurationEls.forEach(e => {
            if (e.checked) {
                //if radio button is checked, set sort style
                duration = e.value + "m";
            }
        });

        let note = document.getElementById('note');
        if(duration == '3m' && s.checked && priceToShow) {
            let p = parseInt(priceToShow/3, 10);
            note.innerHTML = '<i>Patient will pay INR ' + p + ' every month.</i>'
        }
        else if(duration == '6m' && s.checked && priceToShow) {
            let p = parseInt(priceToShow/3, 10);
            note.innerHTML = '<i>Patient will pay INR ' + p + ' every two months.</i>';
        }
        else {
            note.innerHTML = '';
        }
    }


    function selectSex(e) {
        let s;
        if(e.target.id == "sex-f" || e.target.id == "sexs-f") {
            s = document.getElementById("sex-f");
            s.checked = true;
        }
        else {
            s = document.getElementById("sex-m");
            s.checked = true;
        }
    }

    function addPatient(e) {
        e.preventDefault();
        var globalError = false;
        var phoneformat = /^([6-9])(\d{9})$/;
        var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var Error = "This field cannot be empty";
        var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        var agevalueError = "Please provide a age between 18 and 99";
        var name = document.getElementById('p-name');
        var email = document.getElementById('p-email');
        var phone = document.getElementById('p-phone');
        var subCheckEl = document.getElementById('subCheck');
        var subscription = subCheckEl.checked;

        var sexValue;
        var sexEls = document.getElementsByName('sex');
        sexEls.forEach(e => {
            if (e.checked) {
                //if radio button is checked, set sort style
                sexValue = e.value;
            }
        });
        console.log(sexValue);

        var age = document.getElementById('p-age');
        var programrefered = document.getElementById('program-dropdown');
        var programDurationEls = document.getElementsByName('program-duration');
        var programDurationValue;
        programDurationEls.forEach(e => {
            if (e.checked) {
                //if radio button is checked, set sort style
                programDurationValue = e.value + "M";
            }
        });
        console.log(programDurationValue);

        var programQuote = document.getElementById('p-quote');
        var name_error = document.getElementById('nameerror');
        var email_error = document.getElementById('emailerror');
        var phone_error = document.getElementById('phoneerror');
        var age_error = document.getElementById('ageerror');
        var program_error = document.getElementById('programerror');
        var quote_error = document.getElementById('quoteerror');

        if (name.value || phone.value || age.value || programrefered.value || programQuote.value) {
            if (name.value.length > 0) {
                name.classList.remove('invalid');
                name_error.style.display = "none";

                if (phone.value.match(phoneformat)) {
                    phone.classList.remove('invalid');
                    phone_error.style.display = "none";

                    if (age.value >= 1) {
                        age_error.style.display = "none";
                        age.classList.remove("invalid");

                        if (programrefered.value) {
                            program_error.style.display = "none";
                            programrefered.classList.remove("invalid");

                            if(programQuote.value) {
                                let referredProgram;
                                selectedPrograms.forEach(p => {
                                    if(p.id == programrefered.value) {
                                        referredProgram = p;
                                    }
                                });
                                let pd = programDurationValue.toLowerCase();
                                let programCost = referredProgram.quote['cost_' + pd];
                                if(programCost < programQuote.value) {
                                    quote_error.style.display = "none";
                                    programQuote.classList.remove("invalid");
                                }
                                else {
                                    globalError = true;
                                    programQuote.classList.add('invalid');
                                    quote_error.style.display = "inline";
                                    quote_error.innerHTML = "Quote can't be less than ₹ " + programCost;
                                }
                            }
                            else {
                                globalError = true;
                                programQuote.classList.add('invalid');
                                quote_error.style.display = "inline";
                                quote_error.innerHTML = Error;
                            }

                        }
                        else {
                            globalError = true;
                            programrefered.classList.add('invalid');
                            program_error.style.display = "inline";
                            program_error.innerHTML = Error;
                        }
                    }
                    else if (age.value <= 0) {
                        globalError = true;
                        age.classList.add('invalid');
                        age_error.style.display = "inline";
                        age_error.innerHTML = agevalueError;
                    }
                    else {
                        globalError = true;
                        age.classList.add('invalid');
                        age_error.style.display = "inline";
                        age_error.innerHTML = Error;
                    }
                }
                else if (phone.value.length == 0) {
                    globalError = true;
                    phone.classList.add('invalid');
                    phone_error.style.display = "inline";
                    phone_error.innerHTML = Error;
                }
                else {
                    globalError = true;
                    phone.classList.add('invalid');
                    phone_error.style.display = "inline";
                    phone_error.innerHTML = phonevalueError;
                }
                
                /*if (!email || email.value.length == 0 || email.value.match(emailformat)) {
                    email_error.style.display = "none";
                    email.classList.remove("invalid");
                }
                else if () {
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = Error;
                }
                else {
                    globalError = true;
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = emailvalueError;
                }*/
            }
            else {
                globalError = true;
                name_error.style.display = "inline";
                name.classList.add("invalid");
                name_error.innerHTML = Error;
            }
        }
        else {
            globalError = true;
            name_error.style.display = "inline";
            name.classList.add("invalid");
            name_error.innerHTML = namevalueError;

            /*
            email_error.style.display = "inline";
            email.classList.add("invalid");
            email_error.innerHTML = Error;
            */

            phone.classList.add('invalid');
            phone_error.style.display = "inline";
            phone_error.innerHTML = Error;

            age.classList.add('invalid');
            age_error.style.display = "inline";
            age_error.innerHTML = Error;

            programrefered.classList.add('invalid');
            program_error.style.display = "inline";
            program_error.innerHTML = Error;
        }

        if (!globalError) {
            let values = {};
            values.name = name.value;
            values.email = '';//email.value;
            values.phone_num = phone.value;
            values.age = age.value;
            values.sex = sexValue;
            
            values.referred_program_name = programrefered.options[programrefered.selectedIndex].text;
            values.referred_program_id = programrefered.value;
            values.referred_program_duration = programDurationValue;
            values.referred_program_quote = programQuote.value;
            values.referred_program_subscription = subscription;
            
            console.log(values);
            Resource.referPatient(values).then((response) => {
                if (response.success) {
                    Toast.success("Patient added successfully");
                    history.push('/home');
                    goHome();
                }
                else if(response && response.warning) {
                    Toast.warning(response.warning);
                    history.push('/home');
                    goHome();
                }
                else {
                    Toast.error("Patient could not be added. Please try again.");
                }
            });
        }
    }

    function goHome() {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <App />
                </Router>
            </ProvideAuth>,
            document.getElementById('root')
        )
    }
}

export default Addpatientform;