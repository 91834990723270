import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Logo from './Bluebrand';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
import './Terms.css';
import Home from './Home';
import PrivateRoute from './PrivateRoute';
import { ProvideAuth } from '../services/use-auth';

const TermsandConditions = () => {

    const history = useHistory();
    const [showContinue, setShowContinue] = useState(false);
    return (
        <div className="Body">
            <div className="terms-section">
                <div className="logo-blue">
                    <Logo />
                </div>
                <h1 className="title">Terms & conditions</h1>
                <div className="terms">
                    <ul className="terms-conditions">
                        <li className="conditions">Lorem Ipsum.
                        </li>
                        <li className="conditions">
                            Lorem Ipsum
                        </li>
                    </ul>
                    <div className="agreement" onClick={makeChecked}>
                        <input name="isGoing" id="myCheck" type="checkbox" onClick={makeChecked} />
                        <h3 className="agree">I have read the terms & conditions and agree to it</h3>
                    </div>
                </div>
                <div className="terms-continue-btn-wrap">
                {
                        showContinue ?
                            <Link to='/home' className="continue"> <button className="continue-btn">CONTINUE</button></Link>
                            :
                            ''
                    }
                </div>
            </div>
        </div>
    );

    function activateAccount() {
        /*ReactDOM.render (
            <ProvideAuth>
                <Router history={history}>
                    <PrivateRoute>
                        <App />
                    </PrivateRoute>
                </Router>
            </ProvideAuth>,
           document.getElementById("root")
        )*/
    }

    function makeChecked() {
        const ischecked = document.getElementById("myCheck");
        if (ischecked.checked) {
            ischecked.checked = false;
            setShowContinue(false);
        }
        else {
            ischecked.checked = true;
            setShowContinue(true);

        }
    }

}

export default TermsandConditions;