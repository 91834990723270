import busyIcon from './../images/busy.svg';

import React from 'react';

const Loader = (props) => {
    if(props.display == 'show')
        return <div className="busyIcon"><img src={busyIcon}/></div>
    else
        return '';
}

export default Loader;