import React from 'react';
import ReactDOM from 'react-dom';
import shareIcon from './../images/share.svg';
import logo from './../images/logosmall.png';
import closeIcon from './../images/popupclose.svg';
import './Homescreenpopup.css';

const Popup = () => {
    return (
        <div className="popup">
            <div className="popup-content"> 
                <div className="popup-section">
                   <img className="logo" src={logo} alt="image" />  
                   <p className="popup-message">Tap <img className="share" src={shareIcon} alt="image" /> & add it to the home screen</p>
                   <img className="popup-close-icon" src={closeIcon} alt="image" onClick={closePopup}/>
                </div>   
            </div>
        </div>
    );

    function closePopup() {
        const popUp = document.getElementsByClassName("popup")[0];
        popUp.style.display = "none";
    }

    
}
export default Popup;