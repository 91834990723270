import { ProvideAuth, useAuth } from '../services/use-auth';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Navigation from './Navigation';
import { Link } from 'react-router-dom';
import './Menu.css';
import { BrowserRouter as Router, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';
import close from './../images/close.svg';
import ProgramCard from './Programcard';
import PrivateRoute from './PrivateRoute';
import CustomizeProgram from './Customizeprogram';

const Customize = () => {
    const history = useHistory();
    const auth = useAuth();
    const title = "Select Programs";
    const [programs, setPrograms] = useState([]);
    
    useEffect(() => {
        if(auth && auth.user && auth.user.static_data && auth.user.static_data.programs) {
            Resource.getProfile().then((profile) => {
                if(profile.success) {
                    let selectedPrograms = profile.data.metainfo.selected_programs || [];
                    let allPrograms = auth.user.static_data.programs;
                    updateProgramList(selectedPrograms, allPrograms);
                }
                else{
                    Toast.error("Unable to fetch information. Please try in sometime.");
                }
            });
        }
    }, []);

    return (
        <div >
            <Navigation pageTitle={title}/>
            <div className="content">
                <div className="topic">
                    <h1 className="title" id="customize-title">{title}</h1>
                </div>
                <div className='customize'>
                    <div className="card-container">
                        {programs.map((program) => 
                            <ProgramCard customizeProgramHandler={customizeProgram} removeProgramHandler={removeProgram} key={program.id} program={program}></ProgramCard>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

    function removeProgram(program, success_callback) {
        Resource.removeSelectedProgram(program).then((response) => {
            if (response.success) {
                Toast.success("Great!! The program is removed");
                let selectedPrograms = response.data;
                let allPrograms = auth.user.static_data.programs;
                updateProgramList(selectedPrograms, allPrograms);
                success_callback();
            }
            else {
                Toast.error("Server error. Please try again in sometime");
            }
        });
        //setPrograms(updatedPrograms);
    }

    function customizeProgram(program) {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <PrivateRoute>
                        <CustomizeProgram removeProgramHandler={removeProgram} path="/customize-program" program={program} />
                    </PrivateRoute>
                </Router>
            </ProvideAuth>,
            document.getElementById("root")
        )
    }

    function updateProgramList(selectedPrograms, allPrograms) {
        let tempPrograms = [];
        for(let i = 0; i < selectedPrograms.length; i++) {
            tempPrograms.push(selectedPrograms[i])
        }

        for(let i = 0; i < allPrograms.length; i++) {
            let selected = false;
            for(let j = 0; j < selectedPrograms.length; j++) {
                if(allPrograms[i].id == selectedPrograms[j].id) {
                    selected = true;
                    break;
                }
            }
            if(!selected)
                tempPrograms.push(allPrograms[i]);
        }
        setPrograms(tempPrograms);
    }
}

export default withRouter(Customize);

