import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import close from './../images/close.svg';
import homeIcon from './../images/home.svg';
import mywallet from './../images/wallet.svg';
import myaccount from './../images/account.svg';
// import mysettings from './../images/settings.svg';
import signout from './../images/logout.svg';
import './Menu.css';
import { withRouter } from 'react-router-dom';

const Menubar = (props) => {
    const [toggleMenuBar, setToggleMenuBar] = useState(props.openMenu);
    useEffect(() => {
        //history.push('/?menu=true');
    }, []);
    
    return (
        <div className="menubar">
            <nav>
                <div className='closeBtnContainer'>
                    <img src={close} alt="img" className='close-btn'onClick={closeMenu} />
                </div>
                <ul className="nav-links">
                    <li className="nav-items">
                        <a href="/home"><img src={homeIcon} alt="img" />Home</a>
                    </li>
                    <li className="nav-items">
                        <a href="/account"><img src={myaccount} alt="img" />Account</a>
                    </li>
                    <li className="nav-items">
                        <a href="/wallet" ><img src={mywallet} alt="img" />Wallet</a>
                    </li>

                    <li className="nav-items">
                        <a href="/logout" ><img src={signout} alt="img" />Logout</a>
                    </li>
                </ul>

            </nav>
        </div>
    );


    function closeMenu() {
        setToggleMenuBar(false);
    }

}
export default withRouter(Menubar);
