
import React from 'react';
import { useAuth } from '../services/use-auth';
import './Terms.css';

const PatientCard = (props) => {
    const auth = useAuth();
    let patient = props.patientdetails ;
    if(patient.metainfo && patient.metainfo.referrer ) {
        
        if(patient.metainfo.referrer.length > 0 && patient.metainfo.referrer[0].referrer_id == auth.user.id) {
            var referredDate = new Date(patient.metainfo.referrer[0].referred_date).toDateString().replace(/\ \b(?!.*?\ \b)/, ", ");
            var referredProgramName = patient.metainfo.referrer[0].program_referred || patient.metainfo.referrer[0].referred_program_name;
            var referredProgramDuration = patient.metainfo.referrer[0].referred_program_duration || '';
            var referredProgramId = patient.metainfo.referrer[0].referred_program_id
            var age = patient.metainfo.age;
            return (
                <div className="details-card">
                    <h5 className="name"><strong>Name: </strong> {patient.name}</h5>
                    <h5 className="age"><strong>Age: </strong> {age ? age : '-'}</h5>
                    <h5 className="date"><strong>Date visited: </strong>{referredDate}</h5>
            <h5 data-id={referredProgramId} className="program"><strong>Program referred: </strong>{referredProgramDuration} {referredProgramName}</h5>
                </div>
            )
        }
    }

    return ('');
}

export default PatientCard;