import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import './App.css';
import './index.css';

// import Login from './components/Login';
import reportWebVitals from './utils/reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ProvideAuth } from './services/use-auth';
import history from './helpers/auth-header';

ReactDOM.render(
  <ProvideAuth>
    <Router history={history}>
      <App />
    </Router>
  </ProvideAuth>,
  document.getElementById('root')
  
);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.log('New version available!  Ready to update?');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});
serviceWorkerRegistration.pwaTrackingListeners();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
