import axios from 'axios';
import { apiHeader } from '../helpers/auth-header';
import authenticationService from './authentication.service';
import {invalidateUser} from './use-auth';
import BusyLoading from '../helpers/loading';

var apiHost = authenticationService.apiHost;

const Resource = {
    createProfile,
    getPatients,
    getWallet,
    updateProfile,
    getProfile,
    referPatient,
    cashout,
    raiseTicket,
    addUpdateSelectedProgram,
    removeSelectedProgram
};

function createProfile(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/create_auth_profile", data, { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {"success": true, "data": response.data};
    }).catch( (err) => {
        console.log(err);
        BusyLoading.hide();
        return {"error": err, "data": null};
    });
}

function updateProfile(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/update_auth_profile", data, { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {"success": true, "data": response.data};
    }).catch( (err) => {
        console.log(err);
        BusyLoading.hide();
        return {"error": err, "data": null};
    });
}

function getProfile() {
    BusyLoading.show();
    return axios.get(apiHost + "/v1/me", { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {"success": true, "data": response.data};
    }).catch( (err) => {
        console.log(err);
        BusyLoading.hide();
        return {"error": err, "data": null};
    });
}

function referPatient(patientData) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/refer_patient", patientData, {headers: apiHeader()})
    .then( (response) => {
        BusyLoading.hide();
        if(response.data.success)
            return {"success": true, "data": response.data};
        else if(response.data.warning)
            return {"warning": response.data.warning, "data": null}
        else 
            return {"error": true, "data": null};

    }).catch( (err) => {
        console.log(err);
        BusyLoading.hide();
        return {'error': "We are facing some issue. Please try again in sometime.", "data": null};
    });
}

function getPatients() {
    BusyLoading.show();
    return axios.get(apiHost + "/v1/patients", { headers: apiHeader() })
    .then( (response) =>  {
        BusyLoading.hide();
        if(response.data.referred_patients)
            return {'success': true, "data": response.data.referred_patients};
        else
            return {'success': true, "data": []};

    }).catch( (err)=> {
        console.log(err);
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

function getWallet() {
    BusyLoading.show();
    return axios.get(apiHost + "/v1/wallet_info", { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {'success': true, "data": response.data};
    }).catch( (err) => {
        console.log(err);
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

function cashout(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/support_request", {
        type: 'cashout',
        amount: data.amount
    }, 
    { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {'success': true, "data": response.data}
    }).catch( (err) => {
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

function raiseTicket(data) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/support_request", {
        type: 'query',
        message: data.query
    }, 
    { headers: apiHeader() })
    .then( (response) => {
        BusyLoading.hide();
        return {'success': true, "data": response.data}
    }).catch( (err) => {
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

function removeSelectedProgram(program) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/remove_selected_program/" + program.id, {},
    { headers: apiHeader() })
    .then( (response) => {
        let userInfo = localStorage.getItem("currentUser");
        if(userInfo) {
            let userInfoJson = JSON.parse(userInfo);
            userInfoJson.metainfo.selected_programs = response.data;
            localStorage.setItem("currentUser", JSON.stringify(userInfoJson));
        }
        BusyLoading.hide();
        return {'success': true, "data": response.data}
    }).catch( (err) => {
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

function addUpdateSelectedProgram(program) {
    BusyLoading.show();
    return axios.post(apiHost + "/v1/add_update_selected_program", 
    program,
    { headers: apiHeader() })
    .then( (response) => {
        let userInfo = localStorage.getItem("currentUser");
        if(userInfo) {
            let userInfoJson = JSON.parse(userInfo);
            userInfoJson.metainfo.selected_programs = response.data;
            localStorage.setItem("currentUser", JSON.stringify(userInfoJson));
        }
        BusyLoading.hide();
        return {'success': true, "data": response.data}
    }).catch( (err) => {
        BusyLoading.hide();
        return {'error': err, "data": null};
    });
}

export default Resource;