var config;
if(process.env.NODE_ENV == 'production') {
    config = {
        API_HOST : 'https://maasika.in/docpe'
    }
}
else if(process.env.NODE_ENV == 'test') {
    config = {
        API_HOST : 'https://test.maasika.in/docpe'
    }
}
else {
    config = {
        API_HOST : 'https://test.maasika.in/docpe'
    }
}

export default config;