import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import Logo from './Whitebrand';
import Getotp from './Getotp';
import './Login.css';
import {  BrowserRouter as Router,Route ,Link, Switch, Redirect, withRouter} from 'react-router-dom';
import Intropage from './Intropage';
import authenticationService from './../services/authentication.service';
import { useAuth } from '../services/use-auth';
import AuthRoute from './AuthRoute';
import Home from './Home';
import PrivateRoute from './PrivateRoute';

const Login = () => {
    var auth = useAuth();
    
    const [otp, setOtp] = useState(false);
    useEffect(()=>{
        
    }, []);

    if (auth && auth.user) {
        if(auth.user.metainfo && auth.user.metainfo.medical_registration_number != '')
            return <Redirect to="/home" />
        else
            return <Redirect to="/register" />
        
        //return <PrivateRoute> <Home path="/home" /> </PrivateRoute>
    }
    else {
        return otp ? (<AuthRoute>
            <Getotp phoneNumber={otp.phone_number} sessionId={otp.otp_session_id}></Getotp>
            </AuthRoute>
        ) : (
            <AuthRoute>
                <div className="login-section">
                    <div className="logo">
                        <Logo />
                    </div>
                    <div className="input-section">
                        <input type="tel" id="input-field" className="inputField" placeholder="+91   Enter phone number" required/>
                        <span className="field-error" id="phoneerror"></span>
                        <button className="getotp-btn" onClick={handleClick} >GET OTP</button>
                    </div>
                </div>
            </AuthRoute>
        )
        
    }

    function handleClick() {
        
        var phoneformat = /^([6-9])(\d{9})$/;
        var phoneError = "Phone number cannot be empty";
        var phonevalueError = "Please provide a valid phone number";
        var input = document.getElementById("input-field");
        var phone_error = document.getElementById("phoneerror");
        let phone = '+91' + input.value;
        let role = 'doctor';
        if(input.value.length == 0 )
        {
            phone_error.style.display = "inline";
            input.classList.add("invalid");
            phone_error.innerHTML = phoneError;
        }
        else if(input.value.match(phoneformat))
        {
            phone_error.style.display = "none";
            input.classList.remove("invalid");
            //console.log("here");
            authenticationService.generateOTP(phone, role).then(data => {
                setOtp(data);
            });
        }    
        else 
        {
            phone_error.style.display = "inline";
            input.classList.add("invalid");
            phone_error.innerHTML = phonevalueError; 
        }
    }
    
}

 
export default withRouter(Login);


// .substr(input.length - 10)


