import { useAuth } from '../services/use-auth';
import React from 'react';
import { Link } from 'react-router-dom';
import './Modal.css';
import { BrowserRouter as Router, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';

const Cashout = () => {
    const history = useHistory();

    return (
        <div>
            <div className="modal">
                <div className="modal-content">
                    <form id="edit-form">
                        <label>Cashout amount </label>
                        <input type="number" id="amount" defaultValue='0' className="inputField" placeholder="Enter cashout amount" required />
                        <span className="field-error" id="camounterror"></span>
                        <div className="button-section">
                            <button type="button" className="cashout-modal-btn" onClick={confirmCashout}>Cashout</button>
                            <button type="button" className="cancel-btn" onClick={cancelCashout}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )

    function confirmCashout() {
        var Error = "Enter valid amount";
        var amount = document.getElementById('amount');
        var amount_error = document.getElementById('camounterror');
        
        if(amount.value && amount.value > 0) {
            amount.classList.remove('invalid');
            amount_error.style.display = "none";

            Resource.cashout( {
                amount: amount.value
            }).then( (response) => {
                if(response.success) {
                    Toast.success("Request registered successfully. We'll get back soon");
                }
                else {
                    Toast.error("Some error occurred. Please try in sometime");
                }
                history.replace("/wallet");
            })
        }
        else {
            amount.classList.add('invalid');
            amount_error.style.display = "inline";
            amount_error.innerHTML = Error;
        }
    }

    function cancelCashout() {
        history.goBack();
    }
}

export default withRouter(Cashout);

