import { useAuth } from '../services/use-auth';
import React from 'react';
import { Link } from 'react-router-dom';
import './Modal.css';
import { BrowserRouter as Router, Route, Switch, useHistory, withRouter} from 'react-router-dom';

const Logout = () => {
    const auth = useAuth();
    const history = useHistory();

    return (
        <div>
           <div className="modal">
               <div className="modal-content">
                   <h3 className="main-content">Do you want to Logout?</h3>
                   <div className="button-section">
                       <Link to='/'><button type="button" className="logout-btn" onClick={confirmLogout}>Logout</button></Link>
                       <button type="button" className="cancel-btn" onClick={cancelLogout}>Cancel</button>
                   </div>
               </div>
           </div>
        </div>
    )

    function confirmLogout() {
        auth.signout();
    }
 
    function cancelLogout() {
        history.goBack();
    }
}

export default withRouter(Logout);

