import React, { useState, useEffect } from 'react';
import OTPInput from "otp-input-react";
import Logo from './Whitebrand';
import './Login.css';
import Resendbtn from './Resendbtn';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import Home from './Home';
import { ProvideAuth, useAuth } from '../services/use-auth';
import PrivateRoute from './PrivateRoute';
import authenticationService from './../services/authentication.service';

const Getotp = (props) => {
    const [counter, setCounter] = useState(59);
    const [result, setResult] = useState('');
    const [sessionId, setSessionId] = useState(props.sessionId);
    const auth = useAuth();
    const history = props.history;
    useEffect(() => {

        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        console.log(timer);
        if (counter == 0) {
            setResult(<Resendbtn requestOTP={requestOTP} />);
        }
        else {
            setResult(<p className="timer" >0.{counter}s</p>);
        }
        return () => clearInterval(timer);
    }, [counter]);

    const verifyOtp = ()=> {
        var input = document.getElementsByTagName('input');
        var otp = '';
        for (let i = 0; i < input.length; i++) {
            otp += input[i].value;
        }
        
        auth.signinUsingOtp(props.phoneNumber, otp, sessionId).then(user => {

        });
    }; 
    const [OTP, setOTP] = useState("");

    return auth.user ? (<ProvideAuth><Router history={history}>
        <PrivateRoute>
            <Home />
        </PrivateRoute>
    </Router>
    </ProvideAuth>)
    : (
        <div className="otp-section" >
            <div className="logo">
                <Logo />
            </div>
            <div className="get-otp">
                <h3 className="phone">{props.phoneNumber}</h3>
                <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    className="otp-number"
                    inputClassName="input-number"
                />
                {/* <div className="otp-number">
                    <input type="text" id="first" pattern="\d*" maxLength="1" className="num1" ></input>
                    <input type="text" id="second" pattern="\d*" maxLength="1" className="num2" ></input>
                    <input type="text" id="third" pattern="\d*" maxLength="1" className="num3" ></input>
                    <input type="text" id="fourth" pattern="\d*" maxLength="1" className="num4"></input>
                </div> */}
            </div>
            <button className="verify-btn" onClick={verifyOtp}>VERIFY OTP</button>
            <div className="go-back">
                <a href="/login" className="goback-link">Back</a>
                {result}
            </div>
        </div>
    );

    function requestOTP() {
        let phone = props.phoneNumber;
        let role = 'doctor';
        if(phone.length < 13) {
            alert("Error: Invalid phone number");
        }
        else {
            authenticationService.generateOTP(phone, role).then(data => {
                console.log(data);
                setSessionId(data.otp_session_id);
                setCounter(59);
            });
        }
    }
    
}

export default withRouter(Getotp);