import axios from 'axios';
import config from '../config';
import Toast from '../helpers/toaster';
console.log("process.env: " + process.env.NODE_ENV);
console.log("process.env: " + process.env.PUBLIC_URL);
console.log("process.env: " + JSON.stringify(process.env));

var apiHost = config.API_HOST;

let requestOptions = {
    headers: { 'Content-Type': 'application/json' }
};

const authenticationService = {
    generateOTP,
    verifyOTP,
    apiHost
};

function generateOTP(phone_number, role) {
    return axios.post(apiHost + '/v1/otp/request',
        { 'phone_number': phone_number, 'role': role },
        requestOptions)
        .then((response) => {
            return response.data;
        }, (error) => {
            Toast.error("Server error. Please try again.");
        });
}

function verifyOTP(phone_number, otp, otp_session_id) {
    return axios.post(apiHost + '/v1/otp/verify',
        { 'phone_number': phone_number, 'otp': otp, 'otp_session_id': otp_session_id },
        requestOptions)
        .then((response) => {

            var user = response.data;
            return user;

        }, (error) => {
            if ([401, 403].indexOf(error.response.status) !== -1) {
                return null;
            }
            else {
                Toast.error("Incorrect OTP.");
            }
        });
}

export default authenticationService;
