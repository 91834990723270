
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Home from './components/Home';
import Register from './components/Register';
import Account from './components/Account';
import Wallet from './components/Wallet';
import Logout from './components/Logout';
import Login from './components/Login';
import Getotp from './components/Getotp';
import TermsandConditions from './components/Termsandconditions';
import Intropage from './components/Intropage';
import PrivateRoute from './components/PrivateRoute';
import { ProvideAuth } from './services/use-auth';
import Cashout from './components/Cashout';
import Support from './components/Support';
import Customize from './components/Customize';
import Addpatientform from './components/Addpatientform';
// import Cashout from './components/Cashout';

function App() {

  return (
    <Switch>
      <Route exact path="/">
        <Intropage />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <PrivateRoute path='/register' component={Register} />
      <PrivateRoute path='/terms'>
        <TermsandConditions />
      </PrivateRoute>
      <PrivateRoute path='/home'>
        <Home />
      </PrivateRoute>
      <PrivateRoute path='/account'>
        <Account />
      </PrivateRoute>
      <PrivateRoute path='/wallet'>
        <Wallet />
      </PrivateRoute>
      <PrivateRoute path='/logout'>
        <Logout />
      </PrivateRoute>
      <PrivateRoute path='/cashout'>
        <Cashout />
      </PrivateRoute>
      <PrivateRoute path='/support'>
        <Support />
      </PrivateRoute>
      <PrivateRoute path='/customize'>
        <Customize />
      </PrivateRoute>
      <PrivateRoute path="/add-patient">
        <Addpatientform />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(App);
