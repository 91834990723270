import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import rupees from './../images/rupees.svg';
import './Menu.css';
import Resource from '../services/resource.service';
import { useAuth } from '../services/use-auth';
import { Link, withRouter } from 'react-router-dom';
import Toast from '../helpers/toaster';

const Wallet = () => {
    const auth = useAuth();
    const [walletState, setWalletState] = useState({});
    const title = "My Wallet";

    useEffect(() => {
        Resource.getWallet().then((response) => {
            if (response.success) {
                setWalletState(response.data);
            }
            else {
                Toast.error("Server is taking time. Please come back later.");
            }
        });
    }, [auth.user]);

    return (
        <div>
            <Navigation pageTitle={title}/>
            <div className="content">
                <div className="topic">
                    <h1 className="title">My Wallet</h1>
                </div>
                <div className="my-wallet">
                    <div className="card-container">
                        <div className="totalearning-card">
                            <p className="total-earning">Your total earning</p>
                            <h1 className="totalEarning"><img src={rupees} alt="image" />{walletState.total_earning ? walletState.total_earning : 0.0}</h1>
                        </div>
                        <div className="paidout-card">
                            <p className="paidout">Paidout</p>
                            <h1 className="paidOut"><img src={rupees} alt="image" />{walletState.cashedout_earning ? walletState.cashedout_earning : 0.0}</h1>
                        </div>
                        <div className="patientsreferred-card">
                            <p className="patients-referred">Number of patients referred</p>
                            <h1 className="patientsReferred">{walletState.patient_count ? walletState.patient_count : 0}</h1>
                        </div>
                        <div className="patientsonboarded-card">
                            <p className="patients-onboarded">Onboarded patients</p>
                            <h1 className="patientsOnboarded">{walletState.onboarded_patient_count ? walletState.onboarded_patient_count : 0}</h1>
                        </div>
                    </div>
                    <div className='btn-holder'>
                        <Link to="/cashout" className="cashout"><button type="button" value="submit" className="cashout-btn" onClick={cashout}>CASHOUT</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )

    function cashout() {

    }
}

export default withRouter(Wallet);