import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from "react-hook-form";
import close from './../images/close.svg';
import Account from './Account';
import Navigation from './Navigation';
import './Register.css';
import './Login.css';
import PrivateRoute from './PrivateRoute';
import { ProvideAuth, useAuth } from '../services/use-auth';
import { BrowserRouter as Router, Redirect, Switch, Link, withRouter, useHistory } from 'react-router-dom';
import App from './../App';
import Resource from '../services/resource.service';
import Toast from '../helpers/toaster';

const Editaccount = (props) => {

    const auth = useAuth();
    const [staticData, setStaticData] = useState(auth.user.static_data || []);
    const history = useHistory();
    return (
        <div className="edit-page">
            <div className='closeBtnContainer'>
                <Link to="/account" ><img src={close} alt="img" className='close-btn' onClick={backToAccount} /></Link>
            </div>
            <h1 className="title">Edit Your Profile</h1>
            <form id="edit-form">
                <label>First name</label>
                <input type="text" id="fname" defaultValue={props.doctor.first_name} className="inputField" placeholder="Enter your first name" required />
                <span className="field-error" id="fnameerror"></span>

                <label>Last name</label>
                <input type="text" id="lname" defaultValue={props.doctor.last_name} className="inputField" placeholder="Enter your last name" required />
                <span className="field-error" id="lnameerror"></span>

                <label>Email</label>
                <input type="email" id="e-mail" defaultValue={props.doctor.email} className="inputField" placeholder="Enter your email" required />
                <span className="field-error" id="emailerror"></span>

                <label>Clinic name</label>
                <input type="text" id="clinic-name" className="inputField" defaultValue={props.doctor.metainfo.clinic_name} className="inputField" placeholder="Enter your name" required />
                <span className="field-error" id="clinicerror"></span>

                <label>Specialization</label>
                <select defaultValue={props.doctor.metainfo.specialization} id="specialization-dropdown" className="inputField" >
                    <option value="">--- Please select ---</option>
                    {staticData && staticData.specializations && staticData.specializations.map((specialization) =>
                        <option key={specialization} value={specialization}>{specialization}</option>
                    )}
                </select>
                <span className="field-error" id="specializationerror"></span>

                <label>Alternate contact number</label>
                <input type="number" id="phn-number" className="inputField" placeholder="Enter phone number (optional)" required />
                <span className="field-error" id="phoneerror"></span>
                <button type="button" value="submit" className="save-btn" onClick={saveDetails.bind(this)}>SAVE</button>
            </form>
        </div>
    )

    function saveDetails(e) {
        e.preventDefault();
        var globalError = false;
        var phoneformat = /^([6-9])(\d{9})$/;
        var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var emailvalueError = "Please provide a valid email-id";
        var namevalueError = "Please provide a name";
        var phonevalueError = "Please provide a 10 digit valid phone number";
        var Error = "This field cannot be empty";
        var email = document.getElementById("e-mail");
        var first_name = document.getElementById('fname');
        var last_name = document.getElementById('lname');
        var clinicName = document.getElementById('clinic-name');
        var phoneNumber = document.getElementById('phn-number');
        var specialization = document.getElementById('specialization-dropdown');
        var fname_error = document.getElementById('fnameerror');
        var lname_error = document.getElementById('lnameerror');
        var email_error = document.getElementById('emailerror');
        var phone_error = document.getElementById('phoneerror');
        var clinic_error = document.getElementById('clinicerror');
        var specialization_error = document.getElementById('specializationerror');

        if (first_name.value || last_name.value || email.value || phoneNumber.value || clinicName.value || specialization.value) {
            last_name.classList.remove('invalid');
            lname_error.style.display = "none";

            if (first_name.value) {
                first_name.classList.remove('invalid');
                fname_error.style.display = "none";

                if (email.value.match(emailformat)) {
                    email_error.style.display = "none";
                    email.classList.remove("invalid");

                    if (phoneNumber.value.length == 0 || phoneNumber.value.match(phoneformat)) {
                        phone_error.style.display = "none";
                        phoneNumber.classList.remove("invalid");

                        if (clinicName.value || !clinicName.value) {
                            clinic_error.style.display = "none";
                            clinicName.classList.remove("invalid");

                            if (specialization.value) {
                                specialization_error.style.display = "none";
                                specialization.classList.remove("invalid");

                            }
                            else {
                                globalError = true;
            
                                specialization.classList.add('invalid');
                                specialization_error.style.display = "inline";
                                specialization_error.innerHTML = Error;
                            }
                        }
                        /*else {
                            clinicName.classList.add('invalid');
                            clinic_error.style.display = "inline";
                            clinic_error.innerHTML = Error;
                        }*/
                    }
                    /*else if (phoneNumber.value.length == 0) {
                            phoneNumber.classList.add('invalid');
                            phone_error.style.display = "inline";
                            phone_error.innerHTML = Error;
                    } */
                    else 
                    {
                        globalError = true;
                        phoneNumber.classList.add('invalid');
                        phone_error.style.display = "inline";
                        phone_error.innerHTML = phonevalueError;
                    }
                }
                else if (email.value.length == 0) {
                    globalError = true;
            
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = Error;
                }
                else {
                    globalError = true;
            
                    email_error.style.display = "inline";
                    email.classList.add("invalid");
                    email_error.innerHTML = emailvalueError;
                }
            }
            else {
                globalError = true;
                fname_error.style.display = "inline";
                first_name.classList.add("invalid");
                fname_error.innerHTML = namevalueError;
            }
        }
        else {
            globalError = true;
            
            fname_error.style.display = "inline";
            first_name.classList.add("invalid");
            fname_error.innerHTML = namevalueError;

            lname_error.style.display = "inline";
            last_name.classList.add("invalid");
            lname_error.innerHTML = namevalueError;

            email_error.style.display = "inline";
            email.classList.add("invalid");
            email_error.innerHTML = Error;

            phoneNumber.classList.add('invalid');
            phone_error.style.display = "inline";
            phone_error.innerHTML = Error;

            clinicName.classList.add('invalid');
            clinic_error.style.display = "inline";
            clinic_error.innerHTML = Error;

            specialization.classList.add('invalid');
            specialization_error.style.display = "inline";
            specialization_error.innerHTML = Error;
        }
        
        if(!globalError) {
            console.log("here");
            let doctorDetails = {};

            doctorDetails.first_name = first_name.value;
            doctorDetails.last_name = last_name.value || '';
            doctorDetails.email = email.value;
            doctorDetails.contact_number = phoneNumber.value || '';
            doctorDetails.metainfo = {}
            doctorDetails.metainfo.specialization = specialization.value;
            doctorDetails.metainfo.clinic_name = clinicName.value || '';

            Resource.updateProfile(doctorDetails).then((response) => {
                if (response.success) {
                    Toast.success("Great!! Your profile has been updated!");
                    history.push('/account');
                    backToAccount();
                }
                else {
                    Toast.error("You profile could not be updated at the moment. Try again in sometime");
                }
            });
        }

    }

    function backToAccount() {
        ReactDOM.render(
            <ProvideAuth>
                <Router history={history}>
                    <App />
                </Router>
            </ProvideAuth>,
            document.getElementById('root')

        )
    }
}

export default withRouter(Editaccount);